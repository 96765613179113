
.box {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue-background{ 
    background-color: #F8F9FF;
}
.content-container{
    width:370px;
}

.login-label{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 4px;
    text-transform: uppercase;
    opacity: 0.5;
}
.black{
    color: #000000;
}

.sign-in-button{
    background-color: #0B53FC;
    height: 50px;
    width: 160px;
    padding: 17px;
}
.sign-in-button .text{
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 4px;
    color:#fff;
    text-transform: uppercase;
}

.title{
    font-family: Roboto;
    font-style: normal;
    font-weight: 900;
    font-size: 60px;
    line-height: 70px;
    letter-spacing: -1px;
    color: #151515;
}

.subtitle{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    color: #151515;
    opacity: 0.7;
}